* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* --primary-color: #ff3300;
  --primary-color-light: #ff9d84;
  --light-color: #fff4f1;
  --light-color-transperent: #fff4f175;
  --dark-color: #370c00;
  --dark-color-light: #370c0015;
  --dark-color-transperent: #370c000a; */

   --primary-color: #376CA9;
  --primary-color-light: #84ABD7;
  --light-color: #F7FBFE;
  --light-color-transperent: #F7FBFE75;
  --dark-color: #0D160B;
  --dark-color-light: #0D160B15;
  --dark-color-transperent: #0D160B0a;
  
  --red-color: rgb(204, 0, 0);
  --green-color: rgb(0, 128, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
